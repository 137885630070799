<template>

<div class="background">
  <div class="head">
    <img :src="headPic" alt="" style="height: 100%;width: 100%;">
  </div>
  <div class="bottom">
    <div class="title">
      <p>分享共赢 协同制造</p>
      <p>服务中微小企业快速成长</p>
    </div>
    <div class="pageSwitch">
      <div @click="changeType('结构设计')">结构设计</div>
      <div @click="changeType('外观设计')">外观设计</div>
      <div @click="changeType('软硬件开发')">软硬件开发</div>
      <div @click="changeType('手板制造')">手板制造</div>
      <div @click="changeType('生产制造')">生产制造</div>
      <div @click="changeType('包装制造')">包装制造</div>
    </div>
    <div class="list" >

      <div v-for="(item,i) in companies" >
        <div class="card" style="margin-bottom: .4rem;margin-right: .27rem">
          <div class="avatar"><img :src="item.avatar" alt="" style="height: 1.56rem;
  width: 1.56rem;"></div>
          <p class="companyName">{{item.name}}</p>
          <div style="margin-left: .4rem;">
            <div class="tag" v-for="(tag,j) in item.tags">
              <div>
                {{tag}}
              </div>
            </div>
          </div>
          <div class="inf">{{item.basicInf}}</div>
          <button @click="detail(item.id)">查看详情</button>
        </div>
      </div>


    </div>
    <div class="pagination" style="margin-bottom: .85rem;">
      <el-pagination background layout="prev, pager, next,"
                     :page-sizes="[10, 20, 30, 40]"
                     v-model:total="PageInfo.total"
                     v-model:current-page="PageInfo.currentPage"
                     v-model:page-size="PageInfo.pageSize"
                     @click="changeType(this.PageInfo.type)"
                     style="--el-pagination-font-color: #212121;
                            --el-pagination-button-color: #ec414d;
                            --el-pagination-hover-color: #ec414d;
                            --el-pagination-font-size: .14rem;
                            --el-pagination-button-height: .35rem;
                            --el-pagination-button-width: .35rem"/>

    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ChainList",
  filters: {
    ellipsis (_val) {
      if (!_val) return ''
      if (_val.length > 21) {
        return _val.slice(0, 21) + '...'
      }
      return _val
    }
  },
  data() {
    return {
      headPic:'',
      PageInfo: {
        type: '结构设计',
        currentPage: 1,
        pageSize: 8,
        total:0,
      },

      companies: [
        {
          id: '',
          avatar: '',
          name: '',
          tags: [],
          inf: '',
        }]
    }
  },
  mounted() {
    this.changeType("结构设计");
    this.getHeadPic();
  },
  methods: {
    getHeadPic(){
      this.$http({
        method:'get',
        url:'/getHeaderPic',
        data:'',
      }).then(res => {
        this.headPic = res.data.supplyChainHeader;
      })
    },
    changeType(type) {
      this.PageInfo.type = type;
      this.$http({
        method: 'post',
        url: '/supplyChain/chainList',
        data: this.PageInfo,
      }).then(res => {
        this.PageInfo.total = res.data.count;
        this.companies = res.data.suppliers;
      })
    },
    detail: function (index) {
      this.$router.push({path:'/Company',query: {companyId: index}});
    },
  },
  components: {

  },

}

</script>

<style scoped>
.background{
  background-color: rgb(251,249,248);
  height: 23.6rem;
}

.head{
  height: 8.34rem;
  width: 19.2rem;
  background-size: 100% 100%;
}

.bottom{
  padding-left: 1.8rem;
}

.bottom .title p:nth-child(1){
  margin-top: 1rem;
  font-size: .36rem;
  font-weight: 300;
}

.bottom .title p:nth-child(2){
  margin-top: .2rem;
  font-size: .4rem;
  font-weight: 400;
  color: black;
}

.bottom .pageSwitch{
  margin-top: .5rem;

}


.bottom .pageSwitch div{
  position: relative;
  color: #0f0f0f;
  height: .65rem;
  line-height: .65rem;
  font-weight: 300;
  font-size: .21rem;
  float: left;
  margin-right: .2rem;
  /*text-align: center;*/
  background-color: #ffffff;
  border-radius: .05rem;
  box-shadow: 0rem 0rem .02rem rgba(0,0,0,0.3);
  border:0rem solid transparent;
  padding-left:.66rem ;
  /*box-shadow: 0rem 0rem .7 .7 rgb(0,0,0,.7);*/


}

.bottom .pageSwitch div::before{
  content: '';
  position: absolute;
  top: .18rem;
  left: .3rem;
  width: .25rem;
  height: .28rem;
  background:url("../../images/SupplyChainType-1.png");
  background-size: 100% 100%;
}

.bottom .pageSwitch div:nth-child(1){
  width: 1.8rem;
}

.bottom .pageSwitch div:nth-child(2){
  width: 1.83rem;
}


.bottom .pageSwitch div:nth-child(2)::before{
  background:url("../../images/SupplyChainType-2.png");
  background-size: 100% 100%;
}

.bottom .pageSwitch div:nth-child(3){
  width: 2.05rem;
}

.bottom .pageSwitch div:nth-child(3)::before{
  background:url("../../images/SupplyChainType-3.png");
  background-size: 100% 100%;
}

.bottom .pageSwitch div:nth-child(4){
  width: 1.78rem;
}

.bottom .pageSwitch div:nth-child(4)::before{
  background:url("../../images/SupplyChainType-4.png");
  background-size: 100% 100%;
}

.bottom .pageSwitch div:nth-child(5){
  width: 1.82rem;
}

.bottom .pageSwitch div:nth-child(5)::before{
  background:url("../../images/SupplyChainType-5.png");
  background-size: 100% 100%;
}

.bottom .pageSwitch div:nth-child(6){
  width: 1.85rem;
}

.bottom .pageSwitch div:nth-child(6)::before{
  background:url("../../images/SupplyChainType-6.png");
  background-size: 100% 100%;
}

.bottom .pageSwitch div:hover{
  background-color: #ec414d;
  color: #ffffff;
}

.bottom .pageSwitch div:nth-child(1):hover::before{
  background:url("../../images/SupplyChainType-1-1.png");
  background-size: 100% 100%;
}

.bottom .pageSwitch div:nth-child(2):hover::before{
  background:url("../../images/SupplyChainType-2-2.png");
  background-size: 100% 100%;
}

.bottom .pageSwitch div:nth-child(3):hover::before{
  background:url("../../images/SupplyChainType-3-3.png");
  background-size: 100% 100%;
}

.bottom .pageSwitch div:nth-child(4):hover::before{
  background:url("../../images/SupplyChainType-4-4.png");
  background-size: 100% 100%;
}

.bottom .pageSwitch div:nth-child(5):hover::before{
  background:url("../../images/SupplyChainType-5-5.png");
  background-size: 100% 100%;
}

.bottom .pageSwitch div:nth-child(6):hover::before{
  background:url("../../images/SupplyChainType-6-6.png");
  background-size: 100% 100%;
}



.bottom .list{
  /*background-color: #1db628;*/
  margin-top: 1.45rem;
  width: 15.88rem;
  height: 10rem;
}

.bottom .list .card{
  float: left;
  width: 3.7rem;
  height: 4.85rem;
  padding-top: .4rem;
  border-radius: .05rem;
  color: #0f0f0f;
  background-color: white;
  text-align: center;
}

.bottom .list .card .avatar{
  height: 1.56rem;
  width: 1.56rem;
  margin-left: 1.07rem;

}

.bottom .list .card .companyName{
  font-size: .21rem;
  font-weight: 400;
  margin-top: .4rem;
  text-align: center;
}

.bottom .list .card .tag{
  padding: .04rem;
  background-color: rgb(248,248,248);
  float: left;
  margin-top: .1rem;
  margin-right: .1rem;
}

.bottom .list .card .inf{
  width: 90%;
  height: .6rem;
  margin-top: .64rem;
  margin-left: 5%;
  font-size: .14rem;
  font-weight: 300;
  text-align: center;
  overflow: hidden;
}

.bottom .list .card button{
  background-color: rgb(248,248,248);
  margin-top: .25rem;
  margin-left: 2.35rem;
  font-size: .14rem;
  font-weight: 300;
  width: 1rem;
  height: .35rem;
  border-radius: .05rem;

}

.bottom .pagination{
  margin-top: .5rem;
  margin-left: 14.13rem;
}

</style>